<template>
<div class="main-container">
  <div class="title-content">
    <span class="title-text">订单管理设置</span>
    <el-button type="primary" @click="handleSubmit">保存</el-button>
  </div>
  <div class="content-box">
    <el-form :model="setting" ref="settingForm" labelWidth="150px" :rules="rules">
      <el-form-item label="系统操作">
        <el-button type="danger" @click="handleDeleteAllOrder">删除所有订单数据</el-button>
      </el-form-item>
      <el-form-item label="默认展示行数" prop="defaultShowRowNum">
        <el-select v-model="setting.defaultShowRowNum.data">
          <el-option
            v-for="(item, index) in rowNumOptions"
            :key="index"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="提煤单号默认填充值" prop="billNumberDefaultValue">
        <el-input v-model="setting.billNumberDefaultValue.data" />
      </el-form-item>
      <el-form-item prop="refundDeduct">
        <template slot="label" style="display: flex; align-items: center;">
          <span style="margin-right: 4px;">退款扣除金额</span>
          <el-tooltip class="item" effect="dark" placement="bottom">
            <i class="el-icon-info" />
            <div slot="content">
              <span>退款时减去的金额，不可小于0，如订单支付金额未30，扣除金额设置为1，则用户收到的退款金额为29</span>
            </div>
          </el-tooltip>
        </template>
        <el-input v-model="setting.refundDeduct.data" />
      </el-form-item>
      <el-form-item prop="notice" label="公告">
        <el-input type="textarea" v-model="setting.notice.data" />
      </el-form-item>
    </el-form>
  </div>
</div>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "orderManageSetting",
  data() {
    return {
      setting: {},
      rowNumOptions: [{
        label: '20',
        value: 20
      }, {
        label: '30',
        value: 30
      }, {
        label: '50',
        value: 50
      }],
      rules: {
        refundDeduct: [
          { validator: (rule, value, callback) => {
            const refundDeduct = value.data
            if (isNaN(refundDeduct)) {
              callback(new Error('请输入数字'));
            } else if (refundDeduct < 0) {
              callback(new Error('数字必须大于等于0'));
            } else {
              callback();
            }
          }, trigger: 'blur'}
        ]
      }
    }
  },
  created() {
    this.setting = this.cloneDeep(this.orderManageSetting)
  },
  computed: {
    ...mapState([
      'orderManageSetting',
    ])
  },
  methods: {
    ...mapActions({
      setSetting: 'setSetting',
      deleteAllOrders: 'deleteAllOrders',
    }),
    handleSubmit () {
      this.$confirm('保存后立即生效，是否确认保存设置？', '提示', ).then(() => {
        this.$refs.settingForm.validate((valid) => {
          if (valid) {
            this.setLoading(true)
            this.setSetting(this.setting).then(res => {
              this.setLoading(false)
              if (res.paCode !== '000') {
                this.$message.error('设置失败' + res.paMsg)
              } else {
                this.$message.success(res.paMsg || '保存成功')
                this.$emit('refreshSetting')
              }
            })
          } else {
            this.$message({
              message: '内容填写有误，请按照页面提示进行填写和修改',
              type: 'warning'
            })
          }
        })
      })
    },
    handleDeleteAllOrder () {
      this.$confirm('该操作会<span style="color: #ec2c2c;">清空订单数据</span>，是否确认要删除？', '警告', {
        dangerouslyUseHTMLString: true,
        type: 'error'
      }).then(() => {
        this.setLoading(true)
        this.deleteAllOrders().then(res => {
          this.setLoading(false)
          if (res.paCode !== '000') {
            this.$message.error('删除失败' + res.paMsg)
          } else {
            this.$message.success(res.paMsg || '删除成功')
            this.$emit('refreshSetting')
          }
        })
      })
    }
  },
}
</script>

<style lang="less" scoped>
.main-container {
  display: flex;
  flex-direction: column;
  
  .title-content {
    display: flex;
    flex-direction: row;
    padding-bottom: 16px;
    border-bottom: 1px solid #e6e6e6;
    .title-text {
      width: 100%;
      font-size: 22px;
    }
  }
  .content-box {
    width: 100%;
    height: 100%;
    padding: 16px 0;
    box-sizing: border-box;
  }
}
</style>