import api from "../../../api/orderManage/index"

export default {
  updateOrderInfo({}, params) {
    return api.updateOrderInfo(params).then(res => res.data)
  },
  /*
    获取订单列表
    orderStatus:  -1: 已删除 0: 未支付  1: 已支付  2: 已完成  3: 已退款
   */
  getOrderList({}, params) {
    return api.getOrderList(params).then(res => res.data)
  },
  getUnusualOrders({}, params) {
    return api.getUnusualOrders(params).then(res => res.data)
  },
  editOrder({}, params) {
    return api.editOrder(params).then(res => res.data)
  },
  getSetting({}, params) {
    return api.getSetting(params).then(res => res.data)
  },
  setSetting({}, params) {
    return api.setSetting(params).then(res => res.data)
  },
  removeOrder({}, params) {
    return api.removeOrder(params).then(res => res.data)
  },
  refundOrder ({}, params) {
    return api.refundOrder(params).then(res => res.data)
  },
  deleteAllOrders({}, params) {
    return api.deleteAllOrders(params).then(res => res.data)
  }
}