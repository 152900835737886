import Vue from 'vue';
import Vuex from 'vuex';
import actions from './actions'

import orderManage from "./modules/orderManage/index";
import dispatchedManage from "@/store/modules/dispatchedManage";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    destination: null,
    orderManageSetting: {},
    fullScreenLoading: false,
    loginStatus: false,
  },
  mutations: {
    setDestination(state, destination) {
      state.destination = destination;
    },
    setState (state, { stateName, value }) {
      state[stateName] = value
    },
    setLoginStatus (state, status) {
      state.loginStatus = status
    },
  },
  actions: actions.actions,
  getters: {
    destination: (state) => state.destination,
    loginStatus: (state) => state.loginStatus
  },
  modules: {
    orderManage,
    dispatchedManage
  }
});
