<template>
  <div class="nav-content fs-14">
    <div
      v-for="(item, index) in navList"
      :key="index"
      class="nav-item-content pointer"
      :class="{ 'active-nav-item': selectIndex === item.index }"
      @click="handleNavClick(item)">
      <span>{{ item.title }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'leftNav',
  props: {
    navList: {
      type: Array,
      default: () => []
    },
  },
  watch: {
    '$route': {
      handler(to, from) {
        this.selectIndex = to.name
      },
      immediate: true
    }
  },
  data() {
    return {
      selectIndex: 'userOrder',
    }
  },
  methods: {
    handleNavClick(item) {
      this.selectIndex = item.index
      this.$emit('on-select', item)
    },
  },
}
</script>

<style scoped>
.nav-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 200px;
  margin-right: 6px;
  background-color: #fff;
  border-right: 1px solid #e6e6e6;
  
  .nav-item-content {
    height: 36px;
    display: flex;
    align-items: center;
    padding: 10px;
    
    &:hover {
      background-color: #edf4fc;
    }
  }
  .active-nav-item {
    color: #4a90e2;
    background-color: #edf4fc;
  }
}
</style>