<template>
<div class="flex flex-column wh-100">
  <div class="nav-menu">
    <navbar :menuList="navMenu"></navbar>
  </div>
  <div class="router-view-content">
    <router-view></router-view>
  </div>
</div>
</template>

<script>
import navbar from "@/components/navbar/index.vue";
export default {
  name: 'HomePage',
  components: {
    navbar,
  },
  data() {
    return {
      navMenu: [
        {
          name: '订单管理',
          index: 'orderManagement',
        }, {
          name: '派单管理',
          index: 'dispatchManage',
        }
      ],
    }
  },
  created() {
    this.$router.push('/orderManagement')
  },
}

</script>

<style lang="less" scoped>
.router-view-content {
  flex: 1;
  width: 100%;
}
</style>