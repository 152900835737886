import OrderManagement from "@/components/orderManage/index.vue";
import userOrder from "@/components/orderManage/userOrderManage.vue";
import UnusualOrders from "@/components/orderManage/unusualOrderManage.vue";
import orderManageSetting from "@/components/orderManage/setting.vue"

export default {
  router: {
    path: "/orderManagement",
    name: "OrderManagement",
    component: OrderManagement,
    props: true,
    children: [
      {
        path: "userOrder",
        name: "userOrder",
        component: userOrder,
        props: true
      }, {
        path: "unUsualOrder",
        name: "unUsualOrder",
        component: UnusualOrders,
        props: true
      }, {
        path: 'orderManageSetting',
        name: 'orderManageSetting',
        component: orderManageSetting,
        props: true
      }
    ]
  },
}