<template>
<div style="width: 100%; height: 100%; background-color:#ffffff;">
  <vxe-grid
    v-bind="gridOptions"
    :loading="isLoading"
    :columns="tableColumns"
    :data="dataList">
    <template #top>
      <div class="top-content">
        <div style="display: flex; align-items: center;">
          <span class="fs-18 fw-bold title-content">派单管理-当前派单</span>
          <div class="total-count-content">派单数量{{ totalCount }}</div>
          <i v-if="!isLoading" class="el-icon-refresh pointer refresh-icon" @click="refreshData"></i>
          <i v-if="isLoading" class="el-icon-loading pointer refresh-icon"></i>
        </div>
        <el-button type="primary" size="small" style="width: 80px;" @click="orderDispatch">派单</el-button>
      </div>
    </template>
    <template #handle-operation="{row}">
      <div style="display: flex; align-items: center; justify-content: center;">
        <span class="pointer" style="color: #409EFF;" @click="handleEdit(row)">编辑</span>
        <el-divider direction="vertical" />
        <el-popconfirm
          confirm-button-text='确定'
          cancel-button-text='取消'
          icon="el-icon-info"
          icon-color="red"
          title="是否确认删除该条派单信息？"
          @confirm="handleDelete(row)"
        >
          <div slot="reference">
            <span class="pointer" style="color: #ff8080;">删除</span>
          </div>
        </el-popconfirm>
      </div>
    </template>
    <template #cipher-slot="{ row }">
      <div>{{ row.cipher || '/' }}</div>
    </template>
    <template #pager>
      <vxe-pager
        :total="totalCount"
        :page-size="pageSize"
        :current-page.sync="page"
        :page-sizes="[20, 30, 50]"
        :layouts="['Sizes', 'PrevJump', 'PrevPage', 'JumpNumber', 'NextPage', 'NextJump', 'FullJump']"
        @page-change="handlePageChange">
      </vxe-pager>
    </template>
  </vxe-grid>
  <CommonDrawer
    :loading="drawerLoading"
    :propParams="drawerData"
    :showDrawer="showDrawer"
    :destroyOnClose="true"
    @close="closeDispatchDrawer"
  >
    <template slot="drawer-content">
      <div style="width: 100%; height: 100%;">
        <el-form :model="dispatchInfo" ref="dispatchInfo" labelWidth="100px" :rules="rules" style="position: relative; width: 100%; height: 100%">
          <div style="width: 100%; height: 100%; padding: 12px; box-sizing: border-box;">
            <el-form-item label="目的地" prop="destination">
              <el-input v-model="dispatchInfo.destination" />
            </el-form-item>
            <el-form-item label="煤种" prop="coalVar">
              <el-input v-model="dispatchInfo.coalVar" />
            </el-form-item>
            <el-form-item label="运费" prop="cost">
              <el-input v-model="dispatchInfo.cost" />
            </el-form-item>
            <el-form-item label="数量" prop="requirement">
              <el-input v-model="dispatchInfo.requirement" />
            </el-form-item>
            <el-form-item prop="needCipher">
              <template slot="label" style="display: flex; align-items: center;">
                <span style="margin-right: 4px;">派单加密</span>
                <el-tooltip class="item" effect="dark" placement="bottom">
                  <i class="el-icon-info" />
                  <div slot="content">
                    <span>启用派单加密</span>
                  </div>
                </el-tooltip>
              </template>
              <el-switch v-model="dispatchInfo.needCipher" />
            </el-form-item>
            <el-form-item v-if="dispatchInfo.needCipher" prop="cipher">
              <template slot="label" style="display: flex; align-items: center;">
                <span style="margin-right: 4px;">验证信息</span>
                <el-tooltip class="item" effect="dark" placement="bottom">
                  <i class="el-icon-info" />
                  <div slot="content">
                    <span>普通派单请勿填写此项</span>
                  </div>
                </el-tooltip>
              </template>
              <el-input v-model="dispatchInfo.cipher" />
            </el-form-item>
            <el-form-item prop="orderPrice">
              <template slot="label" style="display: flex; align-items: center;">
                <span style="margin-right: 4px;">下单费用</span>
                <el-tooltip class="item" effect="dark" placement="bottom">
                  <i class="el-icon-info" />
                  <div slot="content">
                    <span>用户下单时支付的费用</span>
                  </div>
                </el-tooltip>
              </template>
              <el-input v-model="dispatchInfo.orderPrice" />
            </el-form-item>
          </div>
        </el-form>
      </div>
    </template>
    <div slot="drawer-footer">
      <el-button type="primary" @click="handleDispatch('dispatchInfo')">立即派单</el-button>
      <el-button @click="closeDispatchDrawer">关闭</el-button>
    </div>
  </CommonDrawer>
  <CommonDrawer
    :loading="drawerLoading"
    :propParams="detailParams"
    :showDrawer="showDetail"
    :destroyOnClose="true"
    @close="closeDetailDrawer"
  >
    <template slot="drawer-content">
      <el-form :model="dispatchDetail" labelWidth="100px" style="padding: 12px;">
        <el-form-item label="订单编号">
          <el-input v-model="dispatchDetail.coalId" disabled />
        </el-form-item>
        <el-form-item label="目的地">
          <el-input v-model="dispatchDetail.destination" />
        </el-form-item>
        <el-form-item label="煤种" prop="coalVar">
          <el-input v-model="dispatchDetail.coalVar" />
        </el-form-item>
        <el-form-item label="运费" prop="cost">
          <el-input v-model="dispatchDetail.cost" disabled />
        </el-form-item>
        <el-form-item label="派单数量" prop="requirement">
          <el-input v-model="dispatchDetail.requirement" />
        </el-form-item>
        <el-form-item label="密文">
          <el-input v-model="dispatchDetail.cipher" cipher />
        </el-form-item>
        <el-form-item label="二维码图片">
          <el-input v-model="dispatchDetail.coalQRCode" />
        </el-form-item>
      </el-form>
    </template>
    <div slot="drawer-footer">
      <el-button type="primary" @click="handleSubmit">保存</el-button>
      <el-button @click="closeDetailDrawer">关闭</el-button>
    </div>
  </CommonDrawer>
</div>
</template>

<script>
import {mapActions} from "vuex";
import CommonDrawer from "@/components/common/commonDrawer.vue";

export default {
  name: "currentDispatch",
  components: {
    CommonDrawer
  },
  data() {
    return {
      isLoading: false,
      totalCount: 0,
      dataList: [],
      gridOptions: {
        height: 'auto',
        border: 'full',
        showOverflow: 'tooltip',
        columnConfig: {
          resizable: true,
        },
        rowConfig: {
          isHover: true,
          height: 38,
        },
      },
      tableColumns: [{
        type: "seq",
        width: 60,
        title: " ",
        fixed: "left",
        align: "center",
        resizable: false,
      }, {
        field: "coalId",
        title: "派单编号",
        width: 100,
        minWidth: 100,
        fixed: "left",
        resizable: false,
      }, {
        field: "destination",
        title: "目的地",
        width: 120,
        fixed: "left",
      }, {
        field: "coalVar",
        title: "煤种",
        width: 140,
        minWidth: 140,
      }, {
        field: "cost",
        title: "运费",
        width: 60,
        minWidth: 60,
      }, {
        field: 'orderPrice',
        title: '抢单费用',
        width: 80,
        minWidth: 80,
      }, {
        field: "requirement",
        title: "总派单数量",
        width: 120,
        minWidth: 120,
      }, {
        field: "dispatched",
        title: "已抢单数量",
        width: 120,
        minWidth: 120,
      }, {
        field: "cipher",
        title: "密文",
        width: 100,
        minWidth: 100,
        slots: {
          default: 'cipher-slot'
        }
      }, {
        field: "position",
        title: "打卡定位",
        width: 180,
      }, {
        field: "coalQRCode",
        title: "二维码图片地址",
        minWidth: 120
      }, {
        title: '操作',
        width: 120,
        align: 'center',
        fixed: 'right',
        slots: {
          default: 'handle-operation'
        },
      }],
      drawerLoading: false,
      drawerData: {
        title: '派单',
        showFooter: true
      },
      showDrawer: false,
      dispatchInfo: {},
      rules: {
        destination: [
          { required: true, message: '请输入目的地', trigger: 'blur' }
        ],
        coalVar: [
          { required: true, message: '请输入煤种', trigger: 'blur' }
        ],
        cost: [
          { required: true, message: '请输入运费', trigger: 'blur' }
        ],
        cipher: [
          { required: true, message: '请输入验证信息', trigger: 'blur' }
        ],
        requirement: [
          { required: true, message: '请输入数量', trigger: 'blur' }
        ],
        orderPrice: [
          { required: true, message: '请输入下单费用', trigger: 'blur' }
        ]
      },
      pageSize: 20,
      page: 1,
      showDetail: false,
      detailParams: {},
      dispatchDetail: {},
    }
  },
  created() {
    this.initData()
  },
  methods: {
    ...mapActions({
      dispatchedOrder: 'dispatchedOrder',
      addDispatch: 'addDispatch',
      editDispatch: 'editDispatch',
    }),
    initData () {
      this.isLoading = true
      const params = {
        dispatchStatus: 0,
        pageSize: this.pageSize,
        page: this.page
      }
      this.dispatchedOrder(params).then(res => {
        this.isLoading = false
        if (res.paCode !== '000') {
          this.$message.error('获取派单信息失败' + res.paMsg);
          return
        }
        this.totalCount = res.paData.totalCount;
        this.dataList = res.paData.requests;
      })
    },
    refreshData () {
      this.initData()
    },
    orderDispatch () {
      this.showDrawer = true
    },
    handleDispatch (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const params = this.cloneDeep(this.dispatchInfo)
          params.needCipher = params.needCipher ? 1 : 0
          console.log(params)
          this.drawerLoading = true
          this.addDispatch(params).then(res => {
            this.drawerLoading = false
            if (res.paCode !== '000') {
              this.$message.error(res.paMsg || '操作失败');
            } else {
              this.$message.success(res.paMsg || '派单成功');
              this.closeDispatchDrawer()
              this.refreshData()
            }
          })
        } else {
          this.$message({
            message: '内容填写有误，请按照页面提示进行填写和修改',
            type: 'warning'
          })
          return false;
        }
      })
    },
    closeDispatchDrawer () {
      // 清空表单内容
      this.dispatchInfo = {}
      this.showDrawer = false
    },
    handlePageChange({currentPage, pageSize}) {
      this.pageSize = pageSize
      this.page = currentPage
      this.initData()
    },
    handleDelete (row) {
      const params = {
        coalMsgId: row.coalId,
        dispatchStatus: -1,
      }
      this.isLoading = true
      this.editDispatch(params).then(res => {
        this.isLoading = false
        if (res.paCode !== '000') {
          this.$message.error(res.paMsg || '操作失败');
        } else {
          this.$message.success(res.paMsg || '操作成功');
          this.refreshData()
        }
      })
    },
    handleEdit (row) {
      this.detailParams = {
        title: '详情',
        showFooter: true
      }
      this.dispatchDetail = row
      this.showDetail = true
    },
    closeDetailDrawer () {
      this.showDetail = false
    },
    handleSubmit () {
    
    }
  }
}
</script>

<style scoped lang="less">
.top-content {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 12px;
}
.total-count-content {
  margin-right: 8px;
  font-size: 14px;
  color: #5b6b7e;
  border: 1px solid #5b6b7e;
  border-radius: 5px;
  padding: 1px 6px;
  font-weight: bold;
}
.title-content {
  margin-right: 16px;
}
.refresh-icon {
  font-size: 18px;
  color: #4a90e2;
}
/deep/ .vxe-table--render-default .vxe-header--column:not(.col--ellipsis) {
  padding: 8px 0;
}
</style>