import Vue from "vue";
import VueRouter from "vue-router";

import HomePage from '@/components/home/HomePage.vue';
import Login from "@/components/Login.vue";
// import OrderManagement from "@/components/OrderManagement.vue";
import VehicleDispatch from "@/components/VehicleDispatch.vue";
import ClienteleManagement from "@/components/clientele/ClienteleManagement.vue"
import UploadQRCode from "@/components/UploadQRCode.vue"
import UnusualOrders from '@/components/UnusualOrders.vue'
import BlacklistManagement from '@/components/BlacklistManagement.vue'
import OrderManagement from "@/components/orderManage/index.vue"
import userOrder from '@/components/orderManage/userOrderManage.vue'
import orderManageRouter from '@/router/orderManage/index'
import dispatchManageRouter from "@/router/dispatchManage/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login", // 添加 Login 路由
    name: "Login",
    component: Login,
  }, {
    path: '/homePage',
    name: 'HomePage',
    component: HomePage,
  },
  // 订单管理路由
  orderManageRouter.router,
  // 派单管理路由
  dispatchManageRouter.router,
  {
    path: "/vehicleDispatch",
    name: "VehicleDispatch",
    component: VehicleDispatch,
    props: true
  }, {
    path: "/uploadQRCode",
    name: "UploadQRCode",
    component: UploadQRCode,
    props: true
  }, {
    path: "/clienteleManagement/:destination",
    name: "ClienteleManagement",
    component: ClienteleManagement,
    props: true
  }, {
    path: "/unusualOrders",
    name: "UnusualOrders",
    component: UnusualOrders,
    props: true
  }, {
    path: "/blacklistManagement",
    name: "BlacklistManagement",
    component: BlacklistManagement,
    props: true
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
