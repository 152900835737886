<template>
<el-drawer
  v-loading="loading"
  :title="title"
  :showClose="true"
  :visible.sync="showDrawer"
  :size="size"
  :modalAppendToBody="false"
  :destroyOnClose="destroyOnClose"
  :beforeClose="close"
>
  <div class="drawer-container">
    <div class="drawer-content">
      <slot name="drawer-content"></slot>
    </div>
    <div v-if="showFooter" class="footer-content">
      <slot name="drawer-footer"></slot>
    </div>
  </div>
</el-drawer>
</template>

<script>
export default {
  name: "commonDrawer",
  props: {
    showDrawer: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    destroyOnClose: {
      type: Boolean,
      default: false
    },
    propParams: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  watch: {
    propParams: {
      handler(newVal) {
        this.size = newVal.size || '70%'
        this.title = newVal.title || ''
        this.showFooter = newVal.showFooter || false
      },
      immediate: true,
      deep: true
    }
  },
  data () {
    return {
      size: '70%',
      title: '',
      showFooter: false,
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
::v-deep .el-drawer__header {
  height: 45px;
  margin-bottom: 0;
  padding: 0 0 0 15px;
  border-bottom: 1px solid #e0e0e0;
}
.drawer-container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  
  .drawer-content {
    width: 100%;
    height: 100%;
    position: relative;
    overflow-y: auto;
  }
  
  .footer-content {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 64px;
    min-height: 64px;
    width: 100%;
    padding: 0 16px;
    box-sizing: border-box;
    border-top: 1px solid #e0e0e0;
  }
}
</style>