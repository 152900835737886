import dispatchManage from "@/components/dispatchManage/index.vue";
import currentDispatch from "@/components/dispatchManage/currentDispatch.vue";
import historyDispatch from "@/components/dispatchManage/historyDispatch.vue";
import blacklistManage from '@/components/dispatchManage/blacklistManage.vue'

export default {
  router: {
    path: "/dispatchManage",
    name: "dispatchManage",
    component: dispatchManage,
    props: true,
    children: [
      {
        path: "currentDispatch",
        name: "currentDispatch",
        component: currentDispatch,
        props: true
      }, {
        path: 'historyDispatch',
        name: 'historyDispatch',
        component: historyDispatch,
        props: true
      }, {
        path: 'blacklistManage',
        name: 'blacklistManage',
        component: blacklistManage,
        props: true
      }
    ]
  },
}