<template>
<div class="navbar-container">
  <el-menu
    class="el-menu-content"
    v-bind="menuOptions"
    @select="handleSelectMenu">
    <el-menu-item index="homePage">
      <div class="wh-100 flex flex-center">
        <span :class="{active: activeNavIndex === 'homePage'}" class="nav-text-content">主页</span>
      </div>
    </el-menu-item>
    <el-menu-item
      v-for="item in menuList"
      :key="item.index"
      :index="item.index"
      :route="item.indexPath">
      <div class="wh-100 flex flex-center">
        <span :class="{active: item.index === activeNavIndex}" class="nav-text-content">{{ item.name }}</span>
      </div>
    </el-menu-item>
  </el-menu>
</div>
</template>

<script>
export default {
  name: "navBar",
  props: {
    menuList: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      activeNavIndex: 'orderManagement',
      menuOptions: {
        mode: "horizontal",
        backgroundColor: "#243a5e",
        textColor: '#fff',
        activeTextColor: '#fff',
        defaultActive: this.activeNavIndex,
        menuTrigger: 'click',
        router: true,
      },
    }
  },
  methods: {
    handleSelectMenu(index) {
      this.activeNavIndex = index
      this.$router.push({name: index})
    },
  },
}
</script>


<style lang="less" scoped>
.navbar-container {
  width: 100%;
}
.el-menu-content {
  width: 100%;
  height: 56px;
  overflow: hidden;
  border: 0;
  .active {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60%;
    width: 100%;
    background-color: #08224d;
    border-radius: 6px;
  }
  .nav-text-content {
    font-size: 15px;
    padding: 0 10px;
  }
}
.el-menu--horizontal>.el-menu-item.is-active {
  border-bottom: 0;
}
li.el-menu-item {
  background-color: #243a5e !important;
}
li.el-menu-item:hover {
  background-color: #243a5e !important;
}
</style>