<template>
  <div class="main-container">
    <div class="login">
      <h2>管理员验证码</h2>
      <form @submit.prevent="login" class="login-form">
        <div class="form-group">
          <label for="adm_id">管理员账号:</label>
          <input
            type="text"
            id="adm_id"
            v-model="adm_id"
            required
            class="form-control"
          />
        </div>
        <div class="form-group">
          <label for="adm_psw">管理员密码:</label>
          <input
            type="password"
            id="adm_psw"
            v-model="adm_psw"
            required
            class="form-control"
          />
        </div>
        <div class="form-group">
          <button type="submit" class="submit-btn">登录</button>
        </div>
      </form>
      <div v-if="error" class="error">{{ error }}</div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "UserLogin",
  data() {
    return {
      adm_id: "",
      adm_psw: "",
      error: "",
    };
  },
  methods: {
    async login() {
      try {
        const response = await axios.post(`${this.$base_url}/user/login`, {
          adm_id: this.adm_id,
          adm_psw: this.adm_psw,
        });
        if (response.data.success) {
          this.$emit("login-success", { adm_id: this.adm_id });
          // 将用户信息存储到localStorage中
          localStorage.setItem("loggedIn", "true");
          // localStorage.setItem("user", JSON.stringify(response.data.user));
          localStorage.setItem("user", JSON.stringify({ adm_id: this.adm_id }));

          // 跳转到App.vue界面的默认页面
          let destinationRoute = "WelcomePage"; // 默认跳转页面
          this.$router.push({ name: destinationRoute });
        } else {
          this.error = "用户名或密码错误。";
        }
      } catch (error) {
        this.error = "登陆时出现错误：", error;
      }
    },
  },
};
</script>

<style scoped>
.main-container {
  position: fixed;
  margin: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  //background-image: url(../img/background/loginBG.jpg);
  //background-repeat: no-repeat;
  background-color: #a2a2a2;
  background-size: cover;
}
.login {
  width: 300px;
  margin: 50px;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.521);
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  font-family: "Arial", sans-serif;
}

h2 {
  margin-bottom: 20px;
  text-align: center;
  font-size: 24px;
  color: #333;
}

.login-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

label {
  margin-bottom: 5px;
  font-size: 16px;
  color: #333;
}

.form-control {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px 12px;
  font-size: 16px;
  outline: none;
}

.form-control:focus {
  border-color: #007bff;
}

.submit-btn {
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  font-size: 16px;
  padding: 10px 20px;
  transition: background-color 0.3s;
}

.submit-btn:hover {
  background-color: #0056b3;
}

.error {
  color: red;
  margin-top: 10px;
  font-size: 14px;
  text-align: center;
}
</style>
  