<template>
<div style="width: 100%; height: 100%; background-color:#ffffff;">
  <vxe-grid
    v-bind="gridOptions"
    :loading="isLoading"
    :columns="tableColumns"
    :data="dataList">
    <template #top>
      <div class="top-content">
        <div style="display: flex; align-items: center;">
          <span class="fs-18 fw-bold title-content">派单管理-历史派单</span>
          <div class="total-count-content">派单数量{{ totalCount }}</div>
          <i v-if="!isLoading" class="el-icon-refresh pointer refresh-icon" @click="refreshData"></i>
          <i v-if="isLoading" class="el-icon-loading pointer refresh-icon"></i>
        </div>
        <el-button size="small" style="width: 110px;" @click="orderDispatch">清除历史记录</el-button>
      </div>
    </template>
    <template #handle-operation="{row}">
      <div style="display: flex; align-items: center; justify-content: center;">
        <span class="pointer" style="color: #409EFF;">编辑</span>
        <el-divider direction="vertical" />
        <el-popconfirm
          confirm-button-text='确定'
          cancel-button-text='取消'
          icon="el-icon-info"
          icon-color="red"
          title="是否确认删除该条派单信息？"
          @confirm="handleDelete(row)"
        >
          <div slot="reference">
            <span class="pointer" style="color: #ff8080;">删除</span>
          </div>
        </el-popconfirm>
      </div>
    </template>
    <template #cipher-slot="{ row }">
      <div>{{ row.cipher || '/' }}</div>
    </template>
    <template #pager>
      <vxe-pager
        :total="totalCount"
        :page-size="pageSize"
        :current-page.sync="page"
        :page-sizes="[20, 30, 50]"
        :layouts="['Sizes', 'PrevJump', 'PrevPage', 'JumpNumber', 'NextPage', 'NextJump', 'FullJump']"
        @page-change="handlePageChange">
      </vxe-pager>
    </template>
  </vxe-grid>
</div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "historyDispatch",
  data () {
    return {
      isLoading: false,
      totalCount: 0,
      dataList: [],
      gridOptions: {
        height: 'auto',
        border: 'full',
        showOverflow: 'tooltip',
        columnConfig: {
          resizable: true,
        },
        rowConfig: {
          isHover: true,
          height: 38,
        },
      },
      tableColumns: [{
        type: "seq",
        width: 60,
        title: " ",
        fixed: "left",
        align: "center",
        resizable: false,
      }, {
        field: "coalId",
        title: "派单编号",
        width: 100,
        minWidth: 100,
        fixed: "left",
        resizable: false,
      }, {
        field: "destination",
        title: "目的地",
        width: 120,
        fixed: "left",
      }, {
        field: "coalVar",
        title: "煤种",
        width: 140,
        minWidth: 140,
      }, {
        field: "cost",
        title: "运费",
        width: 60,
        minWidth: 60,
      }, {
        field: 'orderPrice',
        title: '抢单费用',
        width: 80,
        minWidth: 80,
      }, {
        field: "requirement",
        title: "总派单数量",
        width: 120,
        minWidth: 120,
      }, {
        field: "dispatched",
        title: "已抢单数量",
        width: 120,
        minWidth: 120,
      }, {
        field: "cipher",
        title: "密文",
        width: 100,
        minWidth: 100,
        slots: {
          default: 'cipher-slot'
        }
      }, {
        field: "position",
        title: "打卡定位",
        width: 180,
      }, {
        field: "coalQRCode",
        title: "二维码图片地址",
        minWidth: 120
      }, {
        title: '操作',
        width: 120,
        align: 'center',
        fixed: 'right',
        slots: {
          default: 'handle-operation'
        },
      }],
      pageSize: 20,
      page: 1,
    }
  },
  created () {
    this.initData()
  },
  methods: {
    ...mapActions({
      dispatchedOrder: 'dispatchedOrder',
    }),
    initData () {
      this.getDispatchList()
    },
    refreshData () {
      this.initData()
    },
    getDispatchList () {
      const params = {
        dispatchStatus: 1,
        pageSize: this.pageSize,
        page: this.page
      }
      this.isLoading = true
      this.dispatchedOrder(params).then(res => {
        this.isLoading = false
        if (res.paCode !== '000') {
          this.$message.error(res.paMsg || '获取派单信息失败')
        } else {
          this.totalCount = res.paData.totalCount;
          this.dataList = res.paData.requests;
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.top-content {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 12px;
}
.total-count-content {
  margin-right: 8px;
  font-size: 14px;
  color: #5b6b7e;
  border: 1px solid #5b6b7e;
  border-radius: 5px;
  padding: 1px 6px;
  font-weight: bold;
}
.title-content {
  margin-right: 16px;
}
.refresh-icon {
  font-size: 18px;
  color: #4a90e2;
}
/deep/ .vxe-table--render-default .vxe-header--column:not(.col--ellipsis) {
  padding: 8px 0;
}
</style>