<template>
<div class="main-container">
  <vxe-grid
    v-bind="gridOptions"
    :loading="isLoading"
    :columns="tableColumns"
    :data="dataList">
    <template #top>
      <div class="top-content">
        <div style="display: flex; align-items: center;">
          <span class="fs-18 fw-bold title-content">黑名单管理</span>
          <i v-if="!isLoading" class="el-icon-refresh pointer refresh-icon" @click="refreshData"></i>
          <i v-if="isLoading" class="el-icon-loading pointer refresh-icon"></i>
        </div>
        <el-button type="primary" size="small" style="width: 80px;" @click="handleAddInBlacklist">添加</el-button>
      </div>
    </template>
    <template #search-filter="{column}">
      <div class="flex flex-row justify-space-between">
        <span>{{ column.title }}</span>
        <div class="flex">
          <span class="reset-btn pointer" v-show="showHeaderReset(column)" @click="resetSearch(column)">重置</span>
          <el-popover
            ref="popoverRef"
            popper-class="search-filter-popover"
            placement="bottom">
            <div slot="reference">
              <i class="el-icon-search pointer"/>
            </div>
            <div class="search-input-wrapper">
              <el-input
                v-model="searchKeyWords[column.field]"
                :clearable="true"
                class="search-input"
                @keyup.enter.native="handleSearch(column)"
                placeholder="请输入关键字"/>
              <span class="search-confirm" @click="handleSearch(column)">确定</span>
            </div>
          </el-popover>
        </div>
      </div>
    </template>
    <template #handle-operation="{ row }">
      <el-popconfirm
        confirm-button-text='确定'
        cancel-button-text='取消'
        icon="el-icon-info"
        icon-color="red"
        title="是否确认将改用户移出黑名单？"
        @confirm="handleRemove(row)"
      >
        <div slot="reference">
          <span class="pointer" style="color: #ff8080;">移除</span>
        </div>
      </el-popconfirm>
    </template>
    <template #pager>
      <vxe-pager
        :total="totalCount"
        :page-size="pageSize"
        :current-page.sync="page"
        :page-sizes="[20, 30, 50]"
        :layouts="['Sizes', 'PrevJump', 'PrevPage', 'JumpNumber', 'NextPage', 'NextJump', 'FullJump']"
        @page-change="handlePageChange">
      </vxe-pager>
    </template>
  </vxe-grid>
</div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "blacklistManage",
  data () {
    return {
      isLoading: false,
      gridOptions: {
        height: 'auto',
        border: 'full',
        showOverflow: 'tooltip',
        columnConfig: {
          resizable: true,
        },
        rowConfig: {
          isHover: true,
          height: 38,
        },
      },
      tableColumns: [{
        type: "seq",
        width: 60,
        title: "id",
        fixed: "left",
        align: "center",
        resizable: false,
      }, {
        field: "licensePlate",
        title: "车牌号",
        width: 160,
        minWidth: 120,
        slots: {
          header: 'search-filter',
        },
      }, {
        field: "remark",
        title: "备注",
        minWidth: 100
      }, {
        title: '操作',
        fixed: 'right',
        align: 'center',
        width: 120,
        slots: {
          default: 'handle-operation'
        }
      }],
      dataList: [],
      totalCount: 0,
      page: 1,
      pageSize: 20,
      requestParams: {},
      searchKeyWords: {}
    }
  },
  created () {
    this.initData()
  },
  methods: {
    ...mapActions({
      getBlacklist: 'getBlacklist',
      removeBlacklist: 'removeBlacklist',
      addBlacklist: 'addBlacklist'
    }),
    initData () {
      this.formatParams()
      this.getBlacklistData()
    },
    refreshData () {
      this.getBlacklistData()
    },
    formatParams () {
      this.requestParams = {
        page: this.page,
        pageSize: this.pageSize
      }
    },
    handleSearch (column) {
      let searchKey = this.searchParamsKey(column.field)
      if (!this.requestParams.search) {
        this.requestParams.search = {}
      }
      this.requestParams.search[searchKey] = this.searchKeyWords[searchKey]
    },
    showHeaderReset(column) {
      let searchKey = this.searchParamsKey(column.field)
      return !!this.requestParams?.search?.[searchKey]
    },
    resetSearch(column) {
      let searchKey = this.searchParamsKey(column.field)
      delete this.requestParams?.search?.[searchKey]
      delete this.searchKeyWords[searchKey]
    },
    searchParamsKey(field) {
      switch (field) {
        case 'licensePlate':
          return 'licensePlate'
        default:
          return ''
      }
    },
    getBlacklistData () {
      this.isLoading = true
      this.getBlacklist(this.requestParams).then(res => {
        this.isLoading = false
        if (res.paCode !== '000') {
          this.$message.error(res.paMsg || '查询黑名单异常，请联系管理员')
        } else {
          this.dataList = res.paData
        }
      })
    },
    handlePageChange({currentPage, pageSize}) {
      this.pageSize = pageSize
      this.page = currentPage
    },
    handleRemove (info) {
      this.isLoading = true
      this.removeBlacklist({ blacklistId: info.id }).then(res => {
        this.isLoading = false
        if (res.paCode !== '000') {
          this.$message.error(res.paMsg || '移除黑名单异常，请联系管理员')
        } else {
          this.$message.success(res.paMsg || '移除黑名单成功')
          this.refreshData()
        }
      })
    },
    handleAddInBlacklist () {
      this.$prompt( '请输入车牌号', {
        confirmButtonText: '拉黑',
        cancelButtonText: '取消',
        // 这里可以写正则判断
        inputPattern: /.+?/,
        inputErrorMessage: '请填写内容'
      }).then(({ value }) => {
        this.addBlacklist({ licensePlate: value }).then(res => {
          if (res.paCode !== '000') {
            this.$message.error(res.paMsg || '添加黑名单异常，请联系管理员')
          } else {
            this.$message.success(res.paMsg || '添加黑名单成功')
            this.refreshData()
          }
        })
      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: '取消输入'
        // });
      });
    }
  }
}
</script>

<style scoped lang="less">
.main-container {
  .top-content {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 12px;
    
    .title-content {
      margin-right: 10px;
    }
    .refresh-icon {
      font-size: 18px;
      color: #4a90e2;
    }
  }
}

.search-input-wrapper {
  display: flex;
  width: 330px;
  height: 34px;
  align-items: center;
  .search-input {
    flex: 1;
    margin-left: 0;
  }
  /deep/ .el-input__inner {
    border-radius: 5px;
    height: 35px;
  }
  .search-confirm {
    color: #4a90e2;
    margin: 0 8px;
    text-align: center;
    cursor: pointer;
  }
}
/deep/ .vxe-table--render-default .vxe-header--column:not(.col--ellipsis) {
  padding: 8px 0;
}
</style>