const XLSXST = require("xlsx-js-style");
import dayjs from 'dayjs'

// 导出表格
export function tableExport(params) {
  /** 【-----参数说明-----】
   * tableData 表格数据，二维数组（type:[[],[]]）
   * tableColumns 表格列-对象数组（type：[{width:100},{...}]），主要用于设置列宽，一般传入vxe-grid、vxe-table的columns属性即可
   * tableDataType 表格数据类型，(type: [{isNumber: true}, {isNumber: false}, {...}])
   * sheetName 工作表名称，默认为【Sheet0】
   * fileName 文件名，默认为【时间戳_导出表格.xlsx】，如有文件名则为【时间戳_文件名.xlsx】
   * **/
  const {tableData, tableColumns, tableDataType, sheetName, fileName} = params
  // 文件对象
  const workbook = XLSXST.utils.book_new();
  // 工作表对象
  const worksheet = XLSXST.utils.aoa_to_sheet(tableData);

  // 定义边框样式
  const borderStyle = {
    top: { style: 'thin' },
    left: { style: 'thin' },
    bottom: { style: 'thin' },
    right: { style: 'thin' },
  };
  // 需要转换成数字类型的列，便于在excel中计算
  let numberColumnList = []
  tableDataType.forEach((item, index) => {
    if(item.isNumber) {
      numberColumnList.push(getExcelColumnName(index + 1))
    }
  })

  Object.keys(worksheet).forEach((key) => {
    if(numberColumnList.some(element => key.includes(element)) && !key.includes('1')) {
      worksheet[key].t = 'n'
    }
    if(typeof(worksheet[key]) !== 'object') {
      return
    }
    worksheet[key].s = {
      // 样式 注释
      // alignment（对齐方式）：用于控制文本在单元格内的对齐方式。
      /**
       vertical（垂直对齐）：可以是 "center"（居中对齐）、"top"（顶部对齐）或 "bottom"（底部对齐）。
       horizontal（水平对齐）：可以是 "center"（居中对齐）、"left"（左对齐）或 "right"（右对齐）。
       indent（缩进）：用于指定文本的缩进级别，以像素为单位。
       wrapText（换行）：如果设置为 true，则文本超出单元格宽度时将自动换行。
       */
      // font（字体）：用于指定字体的相关属性。
      /**
       name（字体名称）：可以是常见的字体名称，例如 '宋体'、'Arial' 等。
       sz（字体大小）：指定字体的大小，以磅为单位。
       color（字体颜色）：可以使用 RGB 值或预定义的颜色名称来设置字体颜色。
       bold（粗体）：如果设置为 true，则字体将显示为粗体。
       italic（斜体）：如果设置为 true，则字体将显示为斜体。
       underline（下划线）：如果设置为 true，则字体将显示为带有下划线。
       */
      // border（边框）：用于设置单元格的边框样式。
      /**
       top、bottom、left、right：分别表示单元格的上、下、左、右边框样式。
       style（样式）：可以是 "thin"（细线样式）、"medium"（中等线样式）等。
       */
      // 边框
      border: borderStyle,
    }
  })

  function getExcelColumnName(columnNumber) {
    let columnName = '';
    while (columnNumber > 0) {
      let remainder = (columnNumber - 1) % 26;
      columnName = String.fromCharCode(65 + remainder) + columnName;
      columnNumber = Math.floor((columnNumber - 1) / 26);
    }
    return columnName;
  }

  // 设置列宽
  let colWidths = []
  if(tableData.length > 0) {
    tableColumns.forEach(item => {
      colWidths.push({wpx: item.width || 300})
    })
  } else {
    tableData[0].forEach(item => {
      colWidths.push({wpx: 300})
    })
  }
  worksheet['!cols'] = colWidths

  // 将工作表添加到工作簿
  XLSXST.utils.book_append_sheet(workbook, worksheet, sheetName || 'Sheet0');

  // 导出为Excel文件
  let timeStamp = dayjs().format('YYYYMMDDHHmmss').toString()
  let tableName
  if(!!fileName) {
    tableName = fileName.includes('.xlsx') ? fileName : timeStamp + '_' + fileName + '.xlsx'
  } else {
    tableName = timeStamp + '_导出表格.xlsx'
  }
  XLSXST.writeFile(workbook, tableName);
}