<template>
<div class="main-container">
  <vxe-grid
    :loading="isLoading"
    v-bind="gridOptions"
    :columns="tableColumns"
    :data="orderList">
    <template #top>
      <div class="top-content">
        <span class="fs-18 fw-bold title-content">异常订单管理</span>
        <div class="total-count-content">订单数{{ totalCount }}</div>
        <i v-if="!isLoading" class="el-icon-refresh pointer refresh-icon" @click="refreshData"></i>
        <i v-if="isLoading" class="el-icon-loading pointer refresh-icon"></i>
      </div>
    </template>
    <template #search-filter="{column}">
      <div class="flex flex-row justify-space-between">
        <span>{{ column.title }}</span>
        <div class="flex">
          <span class="reset-btn pointer" v-show="showHeaderReset(column)" @click="resetSearch(column)">重置</span>
          <el-popover
            ref="popoverRef"
            popper-class="search-filter-popover"
            placement="bottom">
            <div slot="reference">
              <i class="el-icon-search pointer"/>
            </div>
            <div class="search-input-wrapper">
              <el-input
                v-model="searchKeyWords[column.field]"
                :clearable="true"
                class="search-input"
                @keyup.enter.native="handleSearch(column)"
                placeholder="请输入关键字"/>
              <span class="search-confirm" @click="handleSearch(column)">确定</span>
            </div>
          </el-popover>
        </div>
      </div>
    </template>
    <template #order-status="{column, row}">
      <span>{{ orderStatusText(row.orderStatus) }}</span>
    </template>
    <template #order-time="{column, row}">
      <span>{{ formatTime(row.orderTime) }}</span>
    </template>
    <template #handle-operation="{row}">
      <div class="operate-content">
        <el-popconfirm
          confirm-button-text='确定'
          cancel-button-text='取消'
          icon="el-icon-info"
          icon-color="red"
          title="是否修改该订单状态为已支付？"
          @confirm="handleStore(row)"
        >
          <div slot="reference">
            <span class="pointer handle-btn">入库</span>
          </div>
        </el-popconfirm>
      </div>
    </template>
    <template #pager>
      <vxe-pager
        :total="totalCount"
        :page-size="pageSize"
        :current-page.sync="page"
        :page-sizes="[20, 30, 50]"
        :layouts="['Sizes', 'PrevJump', 'PrevPage', 'JumpNumber', 'NextPage', 'NextJump', 'FullJump']"
        @page-change="handlePageChange">
      </vxe-pager>
    </template>
  </vxe-grid>
</div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import dayjs from "dayjs";
export default {
  name: "unusualOrderManage",
  data() {
    return {
      isLoading: false,
      totalCount: 0,
      page: 1,
      pageSize: 20,
      requestParams: {},
      gridOptions: {
        height: 'auto',
        border: 'full',
        round: true,
        showOverflow: 'tooltip',
        columnConfig: {
          resizable: true,
        },
        rowConfig: {
          isHover: true,
          height: 38,
        },
      },
      tableColumns: [
        {
          type: "seq",
          width: 50,
          title: " ",
          fixed: "left",
          align: "center",
          resizable: false,
        }, {
          field: 'userName',
          title: '姓名',
          width: 120,
        }, {
          field: 'licensePlate',
          title: '车牌号',
          width: 120,
          slots: {
            header: 'search-filter',
          },
        }, {
          field: 'telNumber',
          title: '电话号码',
          width: 160,
        }, {
          field: 'destination',
          title: '目的地',
          width: 140,
          slots: {
            header: 'search-filter'
          },
        }, {
          field: 'price',
          title: '运费',
          width: 80,
        }, {
          field: 'coalVar',
          title: '煤种',
          width: 130,
          slots: {
            header: 'search-filter'
          },
        }, {
          field: 'orderStatus',
          title: '订单状态',
          width: 100,
          slots: {
            default: 'order-status'
          },
        }, {
          field: 'orderTime',
          title: '下单时间',
          slots: {
            default: 'order-time'
          },
          width: 200,
        }, {
          field: 'remark',
          title: '备注',
          minWidth: 200,
        }, {
          field: 'operation',
          title: '操作',
          align: 'center',
          fixed: 'right',
          width: 140,
          slots: {
            default: 'handle-operation'
          },
        }
      ],
      orderList: [],
      searchKeyWords: {},
    }
  },
  computed: {
    ...mapState([
      'orderManageSetting'
    ]),
  },
  watch: {
    orderManageSetting: {
      handler (newVal) {
        if (newVal && JSON.stringify(newVal) !== '{}') {
          this.pageSize = newVal.defaultShowRowNum.data || 20
          this.initData()
        }
      },
      immediate: true
    }
  },
  created() {
    this.initData()
  },
  methods: {
    ...mapActions({
      getOrderList: 'getOrderList',
    }),
    initData() {
      this.formatParams()
      this.getUnusualOrderList()
    },
    formatParams() {
      this.page = 1
      this.requestParams.status = 0
      this.requestParams.search = {}
    },
    getUnusualOrderList() {
      this.isLoading = true
      let params = Object.assign({}, this.requestParams, {
        page: this.page,
        pageSize: this.pageSize,
      })
      this.getOrderList(params).then(res => {
        if(res.paCode !== '000') {
          this.$message.error('获取异常订单失败' + res.paMsg);
        } else {
          this.totalCount = res.paData.totalCount;
          this.orderList = res.paData.requests
        }
      }).finally(() => {
        this.isLoading = false
      })
    },
    refreshData() {
      this.formatParams()
      this.getUnusualOrderList()
    },
    formatTime(time) {
      return dayjs(time).format('YYYY-MM-DD ddd HH:mm:ss')
    },
    orderStatusText (status) {
      switch (status) {
        case 0:
          return '未支付'
        case 1:
          return '已支付'
        case 2:
          return '已完成'
        case 3:
          return '已退款'
        default:
          return '/'
      }
    },
    handleSearch(column) {
      let searchKey = this.searchParamsKey(column.field)
      this.requestParams.search[searchKey] = this.searchKeyWords[searchKey]
      this.getUnusualOrderList()
    },
    searchParamsKey(field) {
      switch (field) {
        case 'licensePlate':
          return 'licensePlate'
        case 'destination':
          return 'destination'
        case 'coalVar':
          return 'coalVar'
        default:
          return ''
      }
    },
    showHeaderReset(column) {
      let searchKey = this.searchParamsKey(column.field)
      return !!this.requestParams?.search?.[searchKey]
    },
    resetSearch(column) {
      let searchKey = this.searchParamsKey(column.field)
      delete this.requestParams?.search?.[searchKey]
      delete this.searchKeyWords[searchKey]
      this.getUnusualOrderList()
    },
    handlePageChange({currentPage, pageSize}) {
      this.pageSize = pageSize
      this.page = currentPage
      this.getUnusualOrderList()
    },
    handleStore(row) {
    
    },
  }
}
</script>

<style scoped>
.main-container {
  width: 100%;
  height: 100%;
  
  .top-content {
    height: 30px;
    display: flex;
    align-items: center;
    padding-bottom: 12px;
  }
  .total-count-content {
    margin-right: 8px;
    font-size: 14px;
    color: #5b6b7e;
    border: 1px solid #5b6b7e;
    border-radius: 5px;
    padding: 1px 3px;
    font-weight: bold;
  }
  .title-content {
    margin-right: 16px;
  }
  .refresh-icon {
    font-size: 18px;
    color: #4a90e2;
  }
  .reset-btn {
    margin-right: 6px;
    font-weight: lighter;
    color: #6891c0;
  }
  .operate-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    .handle-btn {
      color: #4a90e2;
    }
  }
}
.search-input-wrapper {
  display: flex;
  width: 330px;
  height: 34px;
  align-items: center;
  .search-input {
    flex: 1;
    margin-left: 0;
  }
  /deep/ .el-input__inner {
    border-radius: 5px;
    height: 35px;
  }
  .search-confirm {
    color: #4a90e2;
    margin: 0 8px;
    text-align: center;
    cursor: pointer;
  }
}
.mr-rt-10 {
  margin-right: 10px;
}
/deep/ .vxe-table--render-default .vxe-header--column:not(.col--ellipsis) {
  padding: 8px 0;
}
</style>