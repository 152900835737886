import api from '../../../api/dispatchedManage/index'

export default {
  dispatchedOrder({}, params) {
    return api.dispatchedOrder(params).then(res => res.data)
  },
  addDispatch({}, params) {
    return api.addDispatch(params).then(res => res.data)
  },
  editDispatch({}, params) {
    return api.editDispatch(params).then(res => res.data)
  },
  getBlacklist({}, params) {
    return api.getBlacklist(params).then(res => res.data)
  },
  addBlacklist({}, params) {
    return api.addBlacklist(params).then(res => res.data)
  },
  removeBlacklist({}, params) {
    return api.removeBlacklist(params).then(res => res.data)
  },
}