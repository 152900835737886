<template>
<div class="main-container dispatch-manage-container">
  <div class="left-nav-content">
    <leftNav :navList="leftNavList" @on-select="handleNavSelect"></leftNav>
  </div>
  <div class="router-view-content">
    <router-view></router-view>
  </div>
</div>
</template>

<script>
import leftNav from "@/components/leftNav/index.vue";

export default {
  name: "dispatchManage",
  components: {leftNav},
  data () {
    return {
      leftNavList: [{
        title: '当前派单',
        name: 'currentDispatch',
        index: 'currentDispatch',
      }, {
        title: '历史派单',
        name: 'historyDispatch',
        index: 'historyDispatch',
      }, {
        title: '黑名单管理',
        name: 'blacklistManage',
        index: 'blacklistManage',
      }]
    }
  },
  watch: {
    // 观察完整的路由对象
    '$route'(to, from) {
      if(to.path === '/dispatchManage') {
        this.$router.push({ name: 'currentDispatch' }, () => {})
      }
    },
  },
  created() {
    this.$router.push({ name: 'currentDispatch' }, () => {})
  },
  methods: {
    handleNavSelect (navItem) {
      this.$router.push({ name: navItem.name },() => {})
    }
  }
}
</script>

<style scoped lang="less">
.dispatch-manage-container {
  display: flex;
  flex-direction: row;
}
.router-view-content {
  display: flex;
  flex: 1;
  overflow: hidden;
  background-color: #fff;
  padding: 20px 30px;
}
</style>